import { Button, Col, Modal, notification, Row, Spin, Typography } from "antd";
import React, { useEffect, useId, useState } from "react";
import BankStatement from "../bank-statement/BankStatement";
import ApplicationDetails from "../application-details/ApplicationDetails";
import LoanDetails from "../loan-details/LoanDetails";
import PersionalDetails from "../persional-details/PersionalDetails";
import VerifyOTP from "../persional-details/VerifyOTP";
import LeadList from "../lead-list/LeadList";
import Offer from "../offer/Offer";
import ProductFeatures from "../product-features/ProductFeatures";
import StepsLoan from "../steps-loan/StepsLoan";
import imgLogo from "../../assets/logo.svg";
import imgLogo_IM_white from "../../assets/logo_IM_white.png";
import { useParams } from "react-router-dom";
import LowCode from "../low-code/low-code";
import API_SERVICE from "../../services/api-service";
import gtag from "ga-gtag";
import { isRazorPay, updateDropOfStage } from "../../services/Utility";
import BuyLeads from "../BuyLeads/BuyLeads";
import BuyLogin from "../Login/BuyLogin";
import IcicLombard from "../BuyLeads/IcicLombard";
import BuyLeadSuccess from "../BuyLeads/BuyLeadSuccess";
import { loanTypeList } from "../start/start";
import parameters from "../../services/parameters";
import OfferMultilender from "../offer/OfferMultilender";
import IndiaMart from "./IndiaMart";
import LowCodeV2 from "../low-code/low-codeV2";
import OfferMultilenderV2 from "../offer/OfferMultilenderV2";
import productWorkflowConfig from "./productWorkflowConfig.json";

const { Paragraph } = Typography;

export type stages =
  | ""
  | "register"
  | "otp"
  | "loanDetail"
  | "businessDetail"
  | "bankStatement"
  | "offer"
  | "applicationDetails"
  | "flowable"
  | "buyLead"
  | "iciciOffer"
  | "buyLeadOffer"
  | "offerMultilender"
  | "offerMultilenderV2";

const ComplementorWidget: React.FunctionComponent<any> = (props: any) => {
  const [loanType, setLoanType] = useState("");
  const [isV2, setIsV2] = useState(false);
  const [loadingBase, setLoadingBase] = useState(true);
  const [componentCode, setComponentCode] = useState("");
  const [selectedStage, setSelectedStage] = useState(1);
  const [selectedStageName, setSelectedStageName] = useState("" as stages);
  const [businessType, setBusinessType] = useState("");
  const [showList, setShowList] = useState(false);
  const [leadDetail, setLeadDetail] = useState<any>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [complementorUserId, setComplementorUserId] = useState("");
  const [isBack, setIsBack] = useState(false);
  const [taskDefinitionKey, setTaskDefinitionKey] = useState("");
  const [productFormMetadata, setProductFormMetadata] = useState({} as any);
  const [taskId, setTaskId] = useState("");
  const [finalJSONSchema, setFinalJSONSchema] = useState({});
  const [earlySalaryResponse, setEarlySalaryResponse] = useState({});
  const [finalFormData, setFinalFormData] = useState({});
  const [extraData, setExtraData] = useState({} as any);
  const [componentParameters , setComponentParameters] = useState({} as any);
  const [loader, setLoader] = useState(false);
  const [connectorLogo, setConnectorLogo] = useState("");
  const [connectorShortDetails, setConnectorShortDetails] = useState({} as any);
  const [urlType, setURLType] = useState("");
  const [appContentTitle, setAppContentTitle] = useState("");
  const [appContent, setAppContent] = useState("");
  const [utmDetailsDto, setUtmDetailsDto] = useState({} as any);
  const [isOpenAppContent, setIsOpenAppContent] = useState(false);
  const [isPreviousTask, setIsPreviousTask] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const { id, type } = useParams() as any;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const tmpUniqueId = `${Math.random()}${Date.now()}${Math.random()}`;
    setUniqueId(tmpUniqueId);
    window.addEventListener('message', function(event) {
      // console.log("event: ", event)
      uploadLog("Message Event Listener", event?.data, tmpUniqueId);
      if (event?.data?.href) {
        const urlParamsTmp: any = {};
        let searchParams = new URL(event.data.href).searchParams;
        searchParams?.forEach((value, key) => {
          urlParamsTmp[key] = value;
        });
        const utmDetailsDto = {
          id: urlParamsTmp?.utm_id,
          source: urlParamsTmp?.utm_source,
          medium: urlParamsTmp?.utm_medium,
          campaign: urlParamsTmp?.utm_campaign,
          term: urlParamsTmp?.utm_term,
          content: urlParamsTmp?.utm_content,
          utmAdditionalDetails: {
            fbclid: urlParamsTmp?.fbclid,
            twclid: urlParamsTmp?.twclid,
            gclid: urlParamsTmp?.gclid,
          },
        }
        setUtmDetailsDto(utmDetailsDto);
      }
    });

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const utmDetailsDto = {
      id: urlParams?.utm_id,
      source: urlParams?.utm_source,
      medium: urlParams?.utm_medium,
      campaign: urlParams?.utm_campaign,
      term: urlParams?.utm_term,
      content: urlParams?.utm_content,
      utmAdditionalDetails: {
        fbclid: urlParams?.fbclid,
        twclid: urlParams?.twclid,
        gclid: urlParams?.gclid,
      },
    }
    setUtmDetailsDto(utmDetailsDto)

    // console.log("urlParams --- ", urlParams);
    setSelectedStageName("register");

    const findType = loanTypeList?.find((item: any) => item?.code == type);
    setURLType(findType?.code ?? "bil");

    const tmp = findType?.key ?? "BUSINESS_INSTALLMENT_LOAN";
    if (!loanType) {
      setLoanType(tmp);
    }

    let tmpId = id;
    if ((urlParams?.source?.length ?? 0) > 0) {
      tmpId = urlParams?.source;
    }
    if (
      (urlParams?.caseId?.length ?? 0) > 0 &&
      (urlParams?.connectorId?.length ?? 0) > 0 &&
      (urlParams?.mobileNumber?.length ?? 0) > 0
    ) {
      tmpId = urlParams?.connectorId;
      authoriseCase(
        urlParams?.caseId,
        urlParams?.connectorId,
        urlParams?.mobileNumber
      );
    }
    setComplementorUserId(tmpId);
    getConnectorShortDetails(tmpId, urlParams);
    getEnumValues();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const uploadLog = (message: string, params: any, tmpUniqueId?: string) => {
    const param = {
      logProperties: {
        level: "INFO",
        uniqueId: tmpUniqueId ?? uniqueId,
        message: message,
        params: params,
      }
    };
    API_SERVICE.addLog(param)
  };

  const authoriseCase = (caseId, connectorId, mobileNumber) => {
    const param = {
      caseId,
      connectorId,
      mobileNumber,
    };

    API_SERVICE.authoriseCase(param)
      .then(({ data }) => {
        const payload = data?.payload;
        if (payload) {
          localStorage.setItem("user", JSON.stringify(payload));
          getNextTask({
            caseId: payload?.caseId,
            prospectId: payload?.prospectId,
            processInstanceId: payload?.processInstanceId,
            loanType: "Buy Now Pay Later",
          });
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const getConnectorShortDetails = (connId, urlParams) => {
    if (!connId) {
      getPreFlowableProcess(connId, urlParams)
      return;
    }

    const findType = loanTypeList?.find((item: any) => item?.code == type);

    const param = {
      // applicantId: urlParams?.applicantId,
      // loanProductShortCode: findType?.code ?? "bil",
      encryptedData: urlParams?.ldata,
      utmDetailsDto: {
        id: urlParams?.utm_id,
        source: urlParams?.utm_source,
        medium: urlParams?.utm_medium,
        campaign: urlParams?.utm_campaign,
        term: urlParams?.utm_term,
        content: urlParams?.utm_content,
      },
    };

    const sortCode = findType?.code ?? "bil";
    let urlParam = `?loanProductShortCode=${sortCode}`;
    if(urlParams?.applicantId) {
      urlParam = urlParam + `&applicantId=${urlParams?.applicantId}`;
    }

    API_SERVICE.getConnectorShortDetails(sortCode, urlParam, param, connId).then(({ data }) => {
      if (data) {
        if((data?.payload?.connectorId?.length ?? 0) > 0) {
          setComplementorUserId(data?.payload?.connectorId);
        }
        const document = data?.payload?.documentDetails;
        // setConnectorShortDetails(data?.payload);
        if (
          (document?.fileExtn?.length ?? 0) > 0 &&
          (document?.documentDetails?.length ?? 0) > 0
        ) {
          const logoConnector =
            "data:image/" +
            document.fileExtn +
            ";base64," +
            document.documentDetails;
          setConnectorLogo(logoConnector);
        }
      }
    }).catch((error) => {
      notification.error({ message: API_SERVICE.handleErrors(error) });
    }).finally(() => {
      getPreFlowableProcess(connId, urlParams)
    });
  };

  const getPreFlowableProcess = (connId, urlParams) => {
    const findType = loanTypeList?.find((item: any) => item?.code == type);

    const param = {
      // applicantId: urlParams?.applicantId,
      // loanProductShortCode: findType?.code ?? "bil",
      encryptedData: urlParams?.ldata,
      utmDetailsDto: {
        id: urlParams?.utm_id,
        source: urlParams?.utm_source,
        medium: urlParams?.utm_medium,
        name: urlParams?.utm_name,
        term: urlParams?.utm_term,
        content: urlParams?.utm_content,
      },
    };

    const sortCode = findType?.code ?? "bil";
    let urlParam = `?loanProductShortCode=${sortCode}`;
    if(urlParams?.applicantId) {
      urlParam = urlParam + `&applicantId=${urlParams?.applicantId}`;
    }
    if(connId) {
      urlParam = urlParam + `&connectorId=${connId}`;
    }

    API_SERVICE.getPreFlowableProcess(sortCode, urlParam, param, connId).then(({ data }) => {
      if (data) {
        setConnectorShortDetails(data?.payload);
        setIsV2(data?.payload?.startFormAndJourneyDetailsResponse?.v2Enabled ?? false);
        setComponentCode(data?.payload?.startFormAndJourneyDetailsResponse?.form?.componentCode ?? "");
      }
    }).finally(() => setLoadingBase(false));
  };

  const getEnumValues = () => {
    API_SERVICE.getEnumValues().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
      }
    });
  };

  const addGTag = (tagId: string) => {
    gtag("event", tagId, {
      case_id: leadDetail?.caseDetails?.caseId,
      dsa_customer_id: leadDetail?.dsaCustomerId,
      complementorUserId: complementorUserId,
    });
  };

  const complementorLenderAPI = ["Personal Loan", "Buy Now Pay Later"];

  const getPreviousTask = (tmpleadDetail?: any) => {

    if(isV2) {
      getNextTaskV2({...tmpleadDetail, previousStepKey: extraData?.previousStepKey});
      return;
    }

    setLoader(true);
    API_SERVICE.getPreviousTask(
      taskId,
      complementorUserId,
      complementorLenderAPI.includes(tmpleadDetail?.loanType)
    )
      .then((data) => {
        const keyTD = data?.data?.payload?.taskDefinitionKey ?? "";
        console.log('Previous called');
        setIsPreviousTask(true);
        setTaskDefinitionKey(keyTD);
        setTaskId(data?.data?.payload?.taskId);
        const formData = data?.data?.payload?.prevResponseJson;
        if ((formData?.length ?? 0) > 0 && keyTD !== "user_task_bureau_offer_bil") {
          console.log(
            "getPreviousTask -- responseJson: ",
            JSON.parse(formData ?? "")
          );
          setLeadDetail(JSON.parse(formData ?? ""));
          setFinalFormData(JSON.parse(formData ?? ""));
        }

        // let strJsonSchema = data.data.payload.jsonSchema ?? "";
        // strJsonSchema = strJsonSchema?.replaceAll(
        //   "https://uat-common.oneflotest.in/api/",
        //   parameters.ApiUrlTwo
        // );
        // const jsonSchema = JSON.parse(strJsonSchema);

        const jsonSchema = getJSONSchema(
          data?.data?.payload?.jsonSchema ?? "{}"
        );

        if (
          (keyTD === "user_task_upload_bank_statements_finbox" ||
            keyTD === "user_task_upload_bank_statements" ||
            keyTD === "user_task_application_details") &&
          (jsonSchema?.customUI ?? false)
        ) {
          if (keyTD === "user_task_application_details") {
            setSelectedStageName("applicationDetails");
          } else {
            setSelectedStage(3);
            setSelectedStageName("bankStatement");
          }
        } else {
          if (
            (keyTD === "user_task_offer_details" ||
              keyTD === "user_task_offer_details_hl") &&
            (data?.data?.payload?.formData?.length ?? 0) > 0
          ) {
            const formData = JSON.parse(data?.data?.payload?.formData ?? "");
            setFinalFormData(formData);
          }
          if (keyTD === "user_task_bureau_offer_bil") {
            const formData = JSON.parse(data?.data?.payload?.prevResponseJson);
            setEarlySalaryResponse({ topEligibleLenderLoanOffer: [formData], isBureauOffer: true, maxAllowedLoanAmount: formData?.calculatedLoanAmount });
            // const formData = JSON.parse(data?.data?.payload?.formData ?? "");
            // setSelectedStageName("flowable");
            setSelectedStageName("offerMultilender");
            // setFinalFormData(formData);
          } else {
            setSelectedStageName("flowable");
            setFinalJSONSchema(jsonSchema);
          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    str = str?.replaceAll(
      /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
      parameters.ApiUrlOne
    );
    str = str.replaceAll(
      /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
      parameters.ApiUrlTwo
    );
    str = str?.replaceAll(
      /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
      parameters.ApiUrlThree
    );
    return JSON.parse(str);
  };

  const getNextTask = (tmpleadDetail?: any) => {
    // const { caseDetails, prospectId, processInstanceId } =
    //   leadDetail ?? tmpleadDetail ?? finalFormData;

    if(isV2 && tmpleadDetail?.nextTask != "V1") {
      getNextTaskV2(tmpleadDetail);
      return;
    }

    const caseDetails =
      leadDetail?.caseDetails ?? tmpleadDetail?.caseDetails ?? finalFormData;
    const prospectId =
      leadDetail?.prospectId ?? tmpleadDetail?.prospectId ?? finalFormData;
    const processInstanceId =
      leadDetail?.processInstanceId ??
      tmpleadDetail?.processInstanceId ??
      connectorShortDetails?.processInstanceId;
    const caseId = leadDetail?.caseId ?? tmpleadDetail?.caseId ?? "";
    console.log("caseDetails: ", caseDetails);
    console.log("prospectId: ", prospectId);
    console.log("processInstanceId: ", processInstanceId);

    setLoader(true);
    API_SERVICE.getNextTask(
      caseDetails?.prospectId ?? prospectId,
      caseDetails?.processInstanceId ?? processInstanceId,
      complementorUserId,
      complementorLenderAPI.includes(tmpleadDetail?.loanType),
      caseId
    )
      .then((data) => {
        const keyTD = data?.data?.payload?.taskDefinitionKey ?? "";
        setTaskDefinitionKey(keyTD);
        setTaskId(data?.data?.payload?.taskId);

        // let strJsonSchema = data.data.payload.jsonSchema;
        // strJsonSchema = strJsonSchema?.replaceAll(
        //   "https://uat-common.oneflotest.in/api/",
        //   parameters.ApiUrlTwo
        // );
        // const jsonSchema = JSON.parse(strJsonSchema);

        const jsonSchema = getJSONSchema(
          data?.data?.payload?.jsonSchema ?? "{}"
        );

        if (
          (keyTD === "user_task_upload_bank_statements_finbox" ||
            keyTD === "user_task_upload_bank_statements" ||
            keyTD === "user_task_application_details") &&
          (jsonSchema?.customUI ?? false)
        ) {
          if (keyTD === "user_task_application_details") {
            if (loanType != "HOME_LOAN") {
              updateDropOfStage("VIEW_APPLICATION");
            } else {
              if (leadDetail?.loanType == "Business Installment Loan") {
                const formData = JSON.parse(
                  data?.data?.payload?.formData ?? null
                );
                console.log('formData', formData);
                setFinalFormData({ multilenderOffer : formData });
              } else {
                const formData = JSON.parse(
                  data?.data?.payload?.formData ?? "{}"
                );
                setFinalFormData(formData);
              }
            }
            const formData = JSON.parse(
              data?.data?.payload?.formData ?? "{}"
            );
            if (leadDetail?.loanType == "Business Installment Loan") {
              console.log('formData', formData);
              setFinalFormData({ multilenderOffer : formData });
            } else {
              setFinalFormData(formData);
            }
            setSelectedStageName("applicationDetails");
          } else {
            setSelectedStage(3);
            setSelectedStageName("bankStatement");
          }
        } else {
          if(keyTD == "user_task_thank_you_page_bil" && complementorUserId !== 'CONN4128778344') {
            setEarlySalaryResponse({});
            setSelectedStageName("offer");
          } else if (
            ["user_task_offer_details", "user_task_offer_details_hl", "user_task_multi_lender_offer_details_bil", "user_task_bureau_offer_bil"].includes(
              keyTD
            ) &&
            (data?.data?.payload?.formData?.length ?? 0) > 0
          ) {
            const formData = JSON.parse(data?.data?.payload?.formData ?? "{}");
            if (
              loanType == "PERSONAL_LOAN_ONLY" ||
              loanType === "BUY_NOW_PAY_LATER" ||
              tmpleadDetail?.loanType === "Buy Now Pay Later"
            ) {
              setEarlySalaryResponse(formData);
              setSelectedStageName("offer");
            } else if(keyTD === "user_task_multi_lender_offer_details_bil" || keyTD === "user_task_bureau_offer_bil") {
              if (keyTD === "user_task_bureau_offer_bil") {
                setEarlySalaryResponse({ topEligibleLenderLoanOffer: [formData], isBureauOffer: true, maxAllowedLoanAmount: formData?.calculatedLoanAmount });
              } else {
                setEarlySalaryResponse(formData);
              }
              setSelectedStageName("offerMultilender");
            } else {
              setFinalFormData(formData);
              setSelectedStageName("flowable");
            }
            if (loanType != "HOME_LOAN") {
              updateDropOfStage("OFFER_SHOWN");
            }
          } else {
            if ((data?.data?.payload?.formData?.length ?? 0) > 15) {
              const formData = JSON.parse(
                data?.data?.payload?.formData ?? "{}"
              );
              setFinalFormData(formData);
            }
            setSelectedStageName("flowable");
          }
          setFinalJSONSchema(jsonSchema);
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getNextTaskV2 = (tmpleadDetail?: any) => {
    const processInstanceId =
      leadDetail?.caseDetails?.processInstanceId ??
      leadDetail?.processInstanceId ??
      tmpleadDetail?.processInstanceId ??
      tmpleadDetail?.caseDetails?.processInstanceId ??
      connectorShortDetails?.processInstanceId;
    console.log("processInstanceId: ", processInstanceId);

    const payload = {
      // clientType: "MOBILE",
      // eventName: "string",
      gotoStep: tmpleadDetail?.previousStepKey ?? "",
      // key: "string",
      // parameters: {},
      processInstanceId: processInstanceId,
      // taskCategory: "string",
      // workflowId: "string",
    };

    setLoader(true);
    API_SERVICE.getNextTaskV2(payload)
      .then((data) => {
        const payload = data?.data?.payload;
        setProductFormMetadata(payload?.productFormMetadata ?? {});        
        const keyTD = payload?.taskDefinitionKey ?? "";
        setTaskDefinitionKey(keyTD);
        setTaskId(payload?.taskId);
        setExtraData(payload?.extraData ?? {})
        setComponentParameters(payload?.componentParameters ?? {})

        const formData = JSON.parse(payload?.formData ?? null);
        setFinalFormData(formData);

        const jsonSchema = getJSONSchema(payload?.jsonSchema ?? "{}");
        setFinalJSONSchema(jsonSchema);

        if (keyTD === "offerComputation_offer_review_task") { 
          const formData = JSON.parse(payload?.formData ?? "{}");
          setFinalFormData(formData);
          setShowList(false);
          setSelectedStageName("offerMultilenderV2");
        } else if(payload?.componentCode == "REACT_APPLICATION_REVIEW") {
          setSelectedStageName("applicationDetails");
        } else if (payload?.productFormMetadata?.actionId == "BANK_STATEMENT_ANALYSIS" || 
                   payload?.componentCode == "BANK_STATEMENT_UPLOAD"){
          setSelectedStage(3);
          setSelectedStageName("bankStatement");
        } else {
          setSelectedStageName("flowable");
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE?.handleErrors(error) });
      })
      .finally(() => setLoader(false));
  };

  const clickedSubmitTask = (value: any) => {
    console.log("clickedSubmitTask: ", value);

    if(isV2) {
      submitTaskV2(value);
      return;
    }

    let payload = {
      formData: JSON.stringify(value),
      responseToRender: true,
      subTaskName: "",
      taskDefinitionKey: taskDefinitionKey,
      taskId: taskId,
    };

    setLoader(true);
    API_SERVICE.submitTask(
      payload,
      complementorLenderAPI.includes(value?.loanType)
    )
      .then((data) => {
        let processInstanceId = "";

        if ((data?.data?.payload?.responseJson?.length ?? 0) > 0) {
          const jsonSchema = JSON.parse(data.data.payload.responseJson);
          processInstanceId = jsonSchema?.processInstanceId ?? "";
        }

        if (
          (data?.data?.payload?.taskDefinitionKey ?? "") ===
            "user_task_business_details" ||
          (data?.data?.payload?.taskDefinitionKey ?? "") ===
            "user_task_borrower_details" ||
          (data?.data?.payload?.taskDefinitionKey ?? "") ===
            "user_task_borrower_details_hl" ||
          (data?.data?.payload?.taskDefinitionKey ?? "") ===
            "user_task_borrower_details_pl" ||
            (data?.data?.payload?.taskDefinitionKey ?? "") ===
            "user_task_borrower_and_business_details"
        ) {
          const jsonSchema = JSON.parse(data.data.payload.responseJson);
          console.log('submit', jsonSchema);
          setLeadDetail(jsonSchema);
          // processInstanceId = jsonSchema?.processInstanceId ?? "";
        }

        if (loanType != "HOME_LOAN") {
          if (taskDefinitionKey == "user_task_borrower_details") {
            updateDropOfStage("PERSONAL_COMPLETED");
          } else if (taskDefinitionKey == "user_task_business_details") {
            updateDropOfStage("INCOME_COMPLETED");
          }
        }

        getNextTask({
          processInstanceId: processInstanceId,
          loanType: value?.loanType,
        });
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const submitTaskV2 = (value: any) => {
    let payload = {
      formData: JSON.stringify(value),
      responseToRender: true,
      subTaskName: "",
      taskDefinitionKey: taskDefinitionKey,
      taskId: taskId,
    };

    setLoader(true);
    API_SERVICE.submitTaskV2(payload)
      .then((data) => {
        getNextTaskV2({processInstanceId: leadDetail?.caseDetails?.processInstanceId});
      })
      .catch((error) => {
        if(error?.response?.status == 409) {
          getNextTaskV2({processInstanceId: leadDetail?.caseDetails?.processInstanceId});
        } else {
          notification.error({ message: API_SERVICE.handleErrors(error) });
        }
      })
      .finally(() => setLoader(false));
  };

  const getAppContent = (type: string) => {
    API_SERVICE.getAppContent(type).then((data: any) => {
      if (data?.data?.payload.length > 0) {
        setAppContentTitle(
          type == "PRIVACY_POLICY" ? "PRIVACY POLICY" : "TERMS OF USE"
        );
        setAppContent(data.data.payload[0]?.description);
        setIsOpenAppContent(true);
      }
    });
  };


  const modalAppContent = () => {
    return (
      <Modal
        title={appContentTitle}
        open={isOpenAppContent}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              // loading={loading}
              onClick={() => {
                // setAppContentType("")
                // setAppContent("")
                setIsOpenAppContent(false);
              }}
              style={{ paddingInline: "30px" }}
            >
              OK
            </Button>
          </div>,
        ]}
        width={"80%"}
        // style={{ margin: "30px" }}
        className="model-app-content"
        centered
        closable={false}
      >
        <div className="app-content-body">
          <Paragraph>
            {
              <pre
                style={{
                  wordBreak: "break-word",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                {appContent}
              </pre>
            }
          </Paragraph>
        </div>
      </Modal>
    );
  };

  const renderStage = () => {
    // console.log('loanType', loanType);
    if (showList) {
      return renderList();
    } else if (selectedStageName === "register") {
      if ((urlType === "bil" || urlType === "pl") && complementorUserId !== 'CONN4128778344' && !isV2) {
        return (
          <PersionalDetails
            getAppContent={getAppContent}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            setSelectedStageName={setSelectedStageName}
            loanType={loanType}
            utmDetailsDto={utmDetailsDto}
            connectorShortDetails={connectorShortDetails}
            complementorUserId={complementorUserId}
            getNextTask={getNextTask}
            addGTag={addGTag}
            uploadLog={uploadLog}
          />
        );
      } else if ((urlType?.length ?? 0) != 0) {
        return (
          <BuyLogin
            getAppContent={getAppContent}
            setLeadDetail={setLeadDetail}
            getNextTask={getNextTask}
            getNextTaskV2={getNextTaskV2}
            setSelectedStageName={setSelectedStageName}
            loanType={loanType}
            isV2={isV2}
            setIsV2={setIsV2}
            utmDetailsDto={utmDetailsDto}
            connectorShortDetails={connectorShortDetails}
            complementorUserId={complementorUserId}
            setShowList={setShowList}
            uploadLog={uploadLog}
          />
        );
      }
    } else if (selectedStageName === "otp") {
      return (
        <VerifyOTP
          selectedStage={selectedStage}
          setSelectedStage={setSelectedStage}
          setLeadDetail={setLeadDetail}
          loanType={loanType}
          utmDetailsDto={utmDetailsDto}
          setSelectedStageName={setSelectedStageName}
          setShowList={setShowList}
          complementorUserId={complementorUserId}
          getNextTask={getNextTask}
          connectorShortDetails={connectorShortDetails}
          addGTag={addGTag}
          uploadLog={uploadLog}
        />
      );
    } else if (selectedStageName === "loanDetail") {
      return (
        <LoanDetails
          selectedStage={selectedStage}
          setSelectedStage={setSelectedStage}
          leadDetail={leadDetail}
          loanType={loanType}
          isBack={isBack}
          setLeadDetail={setLeadDetail}
          setBusinessType={setBusinessType}
          setSelectedStageName={setSelectedStageName}
          setEarlySalaryResponse={setEarlySalaryResponse}
        />
      );
    } else if (selectedStageName === "bankStatement") {
      return (
        <BankStatement
          selectedStage={selectedStage}
          setSelectedStage={setSelectedStage}
          loanType={loanType}
          isV2={isV2}
          leadDetail={leadDetail}
          setSelectedStageName={setSelectedStageName}
          setIsBack={setIsBack}
          clickedSubmitTask={clickedSubmitTask}
          taskDefinitionKey={taskDefinitionKey}
          getPreviousTask={getPreviousTask}
          addGTag={addGTag}
          complementorUserId={complementorUserId}
        />
      );
    } else if (selectedStageName === "offer") {
      return (
        <Offer
          setSelectedStageName={setSelectedStageName}
          setShowList={setShowList}
          leadDetail={leadDetail}
          earlySalaryResponse={earlySalaryResponse}
          loanType={loanType}
          complementorUserId={complementorUserId}
        />
      );
    } else if (selectedStageName === "applicationDetails") {
      return (
        <ApplicationDetails
          leadDetail={leadDetail}
          extraData={extraData}
          componentParameters={componentParameters}
          setShowList={setShowList}
          finalFormData={finalFormData}
          isMobile={isMobile}
          isV2={isV2}
          loanType={loanType}
          addGTag={addGTag}
          complementorUserId={complementorUserId}
        />
      );
    } else if (selectedStageName === "flowable") {
      if(isV2) {
        return (
          <LowCodeV2
            taskDefinitionKey={taskDefinitionKey}
            finalJSONSchema={finalJSONSchema}
            setSelectedStageName={setSelectedStageName}
            submitTaskV2={submitTaskV2}
            setShowList={setShowList}
            getPreviousTask={getPreviousTask}
            finalFormData={finalFormData}
            addGTag={addGTag}
            leadDetail={leadDetail}
            complementorUserId={complementorUserId}
            setLoader={setLoader}
          />
        );
      } else {
      return (
        <LowCode
          taskDefinitionKey={taskDefinitionKey}
          finalJSONSchema={finalJSONSchema}
          setSelectedStageName={setSelectedStageName}
          clickedSubmitTask={clickedSubmitTask}
          setShowList={setShowList}
          getPreviousTask={getPreviousTask}
          finalFormData={finalFormData}
          addGTag={addGTag}
          leadDetail={leadDetail}
          complementorUserId={complementorUserId}
          setLoader={setLoader}
        />
      );
      }
    } else if (selectedStageName === "buyLead") {
      return (
        <BuyLeads
          setSelectedStageName={setSelectedStageName}
          isMobile={isMobile}
        />
      );
    } else if (selectedStageName === "iciciOffer") {
      return <IcicLombard loanType={loanType} />;
    } else if (selectedStageName === "buyLeadOffer") {
      return <BuyLeadSuccess isMobile={isMobile} />;
    } else if (selectedStageName === "offerMultilender") {
      return <OfferMultilender
        setSelectedStageName={setSelectedStageName}
        setShowList={setShowList}
        leadDetail={leadDetail}
        earlySalaryResponse={earlySalaryResponse}
        loanType={loanType}
        clickedSubmitTask={clickedSubmitTask}
      />;
    } else if (selectedStageName === "offerMultilenderV2") {
      return <OfferMultilenderV2
              extraData={extraData}
              submitTaskV2={submitTaskV2}
              componentParameters={componentParameters}          
              loanType={loanType}
              complementorUserId={complementorUserId}
            />;
    }
  };

  const getMessage = () => {
    if (selectedStageName === "otp") {
      return "simpleProcess";
    } else if (
      selectedStageName === "loanDetail" ||
      (selectedStageName === "flowable" &&
        (taskDefinitionKey == "user_task_borrower_details" ||
          taskDefinitionKey == "user_task_business_details"))
    ) {
      return "trustedNetwork";
    } else if (selectedStageName === "bankStatement") {
      return "minimumDocumentation";
    } else if (
      selectedStageName === "offer" ||
      (selectedStageName === "flowable" &&
        [
          "user_task_offer_details",
          "user_task_offer_details_hl",
          "user_task_bureau_offer_bil",
          "user_task_not_eligible_offer_details_hl",
        ].includes(taskDefinitionKey))
    ) {
      return "digitisedProcess";
    } else if (selectedStageName === "applicationDetails") {
      return "quickestDisbursement";
    } else if (loanType === "HEALTH_INSURANCE") {
      return "superQuickHealthPolicies";
    } else if (loanType === "FOUR_WHEELER_INSURANCE") {
      return "superQuickCarInsurance";
    } else if (loanType === "TWO_WHEELER_INSURANCE") {
      return "superQuickVehicleInsurance";
    }

    return "whoCanApply";
  };

  const renderList = () => {
    return (
      <LeadList
        isMobile={isMobile}
        setSelectedStage={setSelectedStage}
        setSelectedStageName={setSelectedStageName}
        setLeadDetail={setLeadDetail}
        isV2={isV2}
        setShowList={setShowList}
        setLoanType={setLoanType}
        loanType={loanType}
        utmDetailsDto={utmDetailsDto}
        complementorUserId={complementorUserId}
        setTaskDefinitionKey={setTaskDefinitionKey}
        setTaskId={setTaskId}
        setProductFormMetadata={setProductFormMetadata}
        setFinalJSONSchema={setFinalJSONSchema}
        setFinalFormData={setFinalFormData}
        setExtraData={setExtraData}
        setComponentParameters={setComponentParameters}
        addGTag={addGTag}
        setIsV2={setIsV2}
        setEarlySalaryResponse={setEarlySalaryResponse}
        setConnectorShortDetails={setConnectorShortDetails}
        uploadLog={uploadLog}
      />
    );
  };

  const renderView = () => {
    return (
      <Row className= {isRazorPay(complementorUserId) ? 'razorPay_right_screen_bg' :'main_screen_bg'} style={{ height: "100%" }}>
        {!isMobile &&
          !["", "buyLead", "buyLeadOffer"].includes(selectedStageName) && (
            <Col span={8}>
              <ProductFeatures
                isV2={isV2}
                extraData={extraData}
                productFormMetadata={productFormMetadata}
                selectedStageName={selectedStageName}
                productFeaturesMsg={
                  showList ? "completeVisibility" : getMessage()
                }
                loanType={loanType}
                complementorUserId={complementorUserId}
                connectorLogo={
                  complementorUserId == "CONN4128778344"
                    ? imgLogo_IM_white
                    : isRazorPay(complementorUserId) ? connectorLogo : undefined
                }
              />
            </Col>
          )}
        <Col
          span={
            isMobile ||
            selectedStageName === "buyLead" ||
            selectedStageName == "buyLeadOffer"
              ? 24
              : 16
          }
          style={{
            height:
              selectedStageName === "buyLead" ||
              selectedStageName == "buyLeadOffer"
                ? ""
                : "100%",
            overflow: "auto",
            paddingBottom: "10px",
          }}
        >
          {isMobile ? (
            <div
              style={{
                display: "flex",
                justifyContent: (complementorUserId == "CONN4128778344" || isRazorPay(complementorUserId)) ? "center" : "space-between",
                paddingTop: isRazorPay(complementorUserId) ? "0px" : "30px",
                marginInline: "30px",
              }}
            >
              {!["CONN4128778344", "CONN6752220653", "CONN6260068970", "CONN9855119297", "CONN6265370817"].includes(complementorUserId) && !isRazorPay(complementorUserId) && (
                <img src={imgLogo} alt="" className="logo" />
              )}
              <img
                src={connectorLogo}
                alt=""
                width={isRazorPay(complementorUserId) ? 200 : 115}
                height={115}
                style={{ objectFit: "contain" }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "0px",
                marginInline: "20px",
                marginBottom:
                  complementorUserId != "CONN4128778344" && !isRazorPay(complementorUserId) && connectorLogo
                    ? "-70px"
                    : "0px",
              }}
            >
              <img
                src={
                  (complementorUserId == "CONN4128778344" || isRazorPay(complementorUserId)) ? "" : connectorLogo
                }
                alt=""
                width={110}
                height={110}
                style={{ objectFit: "contain" }}
              />
            </div>
          )}

          {showList ? (
            renderList()
          ) : (
            <div style={{ paddingInline: "20px" }}>
              {![
                "",
                "iciciOffer",
                "buyLeadOffer",
                "offer",
                "applicationDetails",
              ].includes(selectedStageName) &&
                taskDefinitionKey != "user_task_offer_details" &&
                taskDefinitionKey != "user_task_offer_details_hl" &&
                taskDefinitionKey != "user_task_bureau_offer_bil" &&
                taskDefinitionKey != "user_task_not_eligible_offer_details" && 
                taskDefinitionKey != "user_task_multi_lender_offer_details_bil" && (
                  <StepsLoan
                    selectedStage={selectedStage}
                    isV2={isV2}
                    extraData={extraData}
                    productFormMetadata={productFormMetadata}
                    loanType={loanType}
                    selectedStageName={selectedStageName}
                    complementorUserId={complementorUserId}
                  />
                )}
              {renderStage()}
            </div>
          )}

          {isMobile && complementorUserId == "CONN4128778344" && (
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              Powered By One Infinity
            </div>
          )}
        </Col>
      </Row>
    );
  };

  const renderIndiaMart = () => {
    return (
      <IndiaMart
        getAppContent={getAppContent}
        selectedStageName={selectedStageName}
        renderStage={renderStage}
        showList={showList}
        taskDefinitionKey={taskDefinitionKey}
        connectorShortDetails={connectorShortDetails}
        getNextTask={getNextTask}
        //
        // taskDefinitionKey={taskDefinitionKey}
        finalJSONSchema={finalJSONSchema}
        setSelectedStageName={setSelectedStageName}
        clickedSubmitTask={clickedSubmitTask}
        setShowList={setShowList}
        getPreviousTask={getPreviousTask}
        finalFormData={finalFormData}
        addGTag={addGTag}
        leadDetail={leadDetail}
        complementorUserId={complementorUserId}
        setLoader={setLoader}
        setLeadDetail={setLeadDetail}
        loanType={loanType}
        isPreviousTask={isPreviousTask}
        setIsPreviousTask={setIsPreviousTask}
        loader={loader}
        isMobile={isMobile}
      />
    );
  };

  if(loadingBase) {
    return <div className={"loader-view"}>
    <Spin
      tip="We are processing your request, please wait"
      size="large"
    />
  </div>
  }

  return (
    <div style={{ height: '100%' }}>
      {modalAppContent()}
      {(complementorUserId == "CONN4128778344" && loanType == "BUSINESS_INSTALLMENT_LOAN") ||
      (complementorUserId == "CONN4128778344" && loanType == "BUSINESS_INSTALLMENT_LOAN" &&
        selectedStageName === "flowable" &&
        [
          "user_task_offer_details",
          "user_task_offer_details_hl",
          "user_task_bureau_offer_bil",
          "user_task_not_eligible_offer_details_hl",
        ].includes(taskDefinitionKey))
        ? renderIndiaMart()
        : renderView()}
      {loader && complementorUserId != "CONN4128778344" && (
        <div className={"loader-view" + (isRazorPay(complementorUserId) ? ' razorpay' :'')}>
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          />
        </div>
      )}
    </div>
  );
};

export default ComplementorWidget;
